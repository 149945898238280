const navigationList = [
  {
    title: 'Calculator',
    children: [
      { path: '/oilwax_calculator', title: 'Oil & Wax Mass' },
      { path: '/production_cost', title: 'Production Cost' },
    ],
  },
  {
    title: 'Colors',
    children: [
      { path: '/design', title: 'Design' },
      { path: '/designs', title: 'List' },
      { path: '/colors', title: 'Colors' },
      { path: '/pantone', title: 'Pantone' },
      { path: '/tint_history', title: 'Tint History' },
    ],
  },
  {
    title: 'Testing',
    children: [
      { path: '/wick_configurator', title: 'Wick Configurator' },
      { path: '/candles', title: 'Candles' },
      { path: '/wick_test', title: 'Wick Testing' },
      { path: '/burn_testing', title: 'Burn Test' },
      { path: '/lifetime_test', title: 'Burning Time' },
      { path: '/path', title: 'Weight Loss', disabled: true },
      { path: '/soot_test', title: 'Soot Index' },
      { path: '/path', title: 'Heat Release', disabled: true },
      { path: '/path', title: 'CO Production', disabled: true },
    ],
  },
  {
    title: 'Labels',
    children: [
      { path: '/labels', title: 'Labels' },
      { path: '/path', title: 'Templates', disabled: true },
      { path: '/path', title: 'Price List', disabled: true },
    ],
  },
  {
    title: 'PCN',
    children: [
      { path: '/path', title: 'SDS', disabled: true },
      { path: '/path', title: 'CLP Label Design Tool', disabled: true },
      { path: '/pcn_shortcuts', title: 'Shortcut' },
    ],
  },
  {
    title: 'Recipes',
    children: [
      { path: '/path', title: 'Candle Science', disabled: true },
      { path: '/path', title: 'Candle Shack', disabled: true },
      { path: '/path', title: 'Crystal Candle', disabled: true },
      { path: '/path', title: 'Candlely', disabled: true },
    ],
  },
  {
    title: 'Documents',
    admin: true,
    children: [
      { path: '/invoices', title: 'Invoices' },
      { path: '/files', title: 'Documents' },
      { path: '/notes', title: 'Notes' },
      { path: '/picture_upload', title: 'Picture Upload' },
    ],
  },
];

export default navigationList;
